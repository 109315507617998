import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vueCustomElement from 'vue-custom-element'
import VueMask from 'v-mask'

Vue.config.productionTip = false
Vue.use(vueCustomElement)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMask)
Vue.customElement('vue-widget', App)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
