<template>
  <div>
    <b-container>
      <b-form
          bg-variant="light"
          @submit="onSubmit"
          class="mt-1"
          validated
      >
        <b-form-row>
          <b-col>
            <b-card bg-variant="light"
                    label-cols-lg="3"
                    title="Идентификаторы"
                    label-size="sm"
                    label-class="font-weight-bold pt-0"
                    class="mb-2"
            >
              <b-form-group
                  label-cols-sm="3"
                  label="Поле для поиска"
                  label-align-sm="right"
                  label-for="load_field"
              >
                <b-form-select
                    id="load_field"
                    v-model="selected_load_field"
                    :options="load_field"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Поле для поиска --</b-form-select-option>
                  </template>
                </b-form-select>

              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="Регистрационный знак"
                  label-align-sm="right"
                  label-for="regSign"
              >

                <b-form-input id="regSign"
                              pattern="[А-Я]{1}[0-9]{3}[А-Я]{2}[0-9]{2,3}"
                              type="text"
                              v-model="form.regSign"
                              :formatter="formatter"
                              title='Заполнять русскими буквами. Формат А111AA11'
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  Заполнять русскими буквами. Формат А111AA11
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="VIN"
                  label-align-sm="right"
                  label-for="vin"
              >
                <b-form-input id="vin"
                              v-model="form.vin"
                              type="text"
                              :formatter="formatter"
                              pattern="[0-9A-Z]{17}"
                              title='VIN должен состоять из 17 символов (Допускаются цифры и латинские буквы)'
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  VIN должен состоять из 17 символов (Допускаются цифры и латинские буквы)
                </b-form-invalid-feedback>
              </b-form-group>


              <b-form-group
                  label-cols-sm="3"
                  label=""
                  label-align-sm="right"
                  id="load_button"
              >
                <b-overlay
                    :show="busy"
                    rounded="10"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                  <b-button
                      data-v-step="load"
                      ref="button"
                      variant="outline-success"
                      :disabled="busy"
                      @click="loadCardData">
                    Подгрузить данные
                  </b-button>
                </b-overlay>

              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="Номер кузова"
                  label-align-sm="right"
                  label-for="bodyNum"
              >
                <b-form-input id="bodyNum" v-model="form.bodyNum"/>
              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="Номер рамы"
                  label-align-sm="right"
                  label-for="chassis"
              >
                <b-form-input id="chassis" v-model="form.chassis"/>
              </b-form-group>
            </b-card>
            <b-card bg-variant="light"
                    class="mb-2"
                    label-cols-lg="3"
                    title="Контактные данные"
                    label-size="sm"
                    label-class="font-weight-bold pt-0"
            >
              <b-form-group
                  label-cols-sm="3"
                  label="Ваш номер телефона"
                  label-align-sm="right"
                  label-for="phone"
              >
                <b-form-input id="phone" type="tel" v-model="form.phone" pattern="[+]{1}[7]{1}[0-9]{10}" trim required
                              placeholder="+7**********" title='+7**********'/>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Ваш email"
                  label-align-sm="right"
                  label-for="email"
              >
                <b-form-input id="email" type="email" v-model="form.email"
                              pattern="^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$"
                              required/>
              </b-form-group>
            </b-card>


          </b-col>
          <b-col>
            <b-card bg-variant="light"
                    label-cols-lg="3"
                    title="Данные о владельце ТС"
                    label-size="sm"
                    label-class="font-weight-bold pt-0"
                    class="mb-2"
            >
              <b-form-group
                  label-cols-sm="3"
                  label="Тип"
                  label-align-sm="right"
                  label-for="owType"
              >
                <b-form-select id="owType" v-model="form.owType" :options="owTypeOptions" required/>
              </b-form-group>
              <template v-if="form.owType === 1">
                <b-form-group
                    label-cols-sm="3"
                    label="Фамилия"
                    label-align-sm="right"
                    label-for="owSurname"
                >
                  <b-form-input id="owSurname" v-model="form.owSurname" required/>
                </b-form-group>

                <b-form-group
                    label-cols-sm="3"
                    label="Имя"
                    label-align-sm="right"
                    label-for="owName"
                >
                  <b-form-input id="owName" v-model="form.owName" required/>
                </b-form-group>

                <b-form-group
                    label-cols-sm="3"
                    label="Отчество"
                    label-align-sm="right"
                    label-for="owParent"
                >
                  <b-form-input id="owParent" v-model="form.owParent"/>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group
                    label-cols-sm="3"
                    label="Название компании"
                    label-align-sm="right"
                    label-for="compName"
                >
                  <b-form-input id="compName" v-model="form.compName"/>
                </b-form-group>
                <b-form-group
                    label-cols-sm="3"
                    label="Фамилия ген.директора"
                    label-align-sm="right"
                    label-for="owSurname"
                >
                  <b-form-input id="owSurname" v-model="form.owSurname" required/>
                </b-form-group>

                <b-form-group
                    label-cols-sm="3"
                    label="Имя ген.директора"
                    label-align-sm="right"
                    label-for="owName"
                >
                  <b-form-input id="owName" v-model="form.owName" required/>
                </b-form-group>
              </template>
            </b-card>
            <b-card bg-variant="light"
                    label-cols-lg="3"
                    title="Данные о регистрации ТС"
                    label-size="sm"
                    label-class="font-weight-bold pt-0"
                    class="mb-2"
            >
              <b-form-group
                  label-cols-sm="3"
                  label="Документ"
                  label-align-sm="right"
                  label-for="docType"
              >
                <b-form-select id="docType" v-model="form.docType" :options="docTypeOptions" required/>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Серия"
                  label-align-sm="right"
                  label-for="serial"

              >
                <b-form-input
                    id="serial"
                    v-model="form.serial"
                    :formatter="formatter"
                    pattern="[0-9]{2}[0-9]{2}|[0-9]{2}[А-Я]{2}"
                    type="text"
                    aria-describedby="input-live-help input-live-feedback"
                    title='4 цифры или две цифры и две буквы (кириллица)'

                    trim
                    required/>
                <b-form-invalid-feedback id="input-live-feedback">
                  4 цифры для СТС или 2 цифры и две русские буквы для ПТС
                </b-form-invalid-feedback>

              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Номер"
                  label-align-sm="right"
                  label-for="number"
              >
                <b-form-input
                    id="number"
                    v-model="form.number"
                    type="text"
                    pattern="[0-9]{6}"
                    required
                    aria-describedby="input-live-help input-live-feedback"
                    :formatter="formatter"
                    trim
                    title='6 цифр'
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  Номер документа должен состоять из 6 цифр
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Кем выдан"
                  label-align-sm="right"
                  label-for="giver"
              >
                <b-form-input id="giver" v-model="form.giver" required/>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Дата выдачи"
                  label-align-sm="right"
                  label-for="date"
              >
                <b-form-input id="date" v-model="form.date" type="date" required/>
              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="Категория"
                  label-align-sm="right"
                  label-for="category"
              >
                <b-form-select id="category" v-model="form.category" :options="categoryOptions" required/>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card bg-variant="light"
                    label-cols-lg="3"
                    title="Данные о ТС"
                    label-size="sm"
                    label-class="font-weight-bold pt-0"
                    class="mb-2"
            >
              <b-form-group
                  label-cols-sm="3"
                  label="Марка"
                  label-align-sm="right"
                  label-for="make"
              >
                <b-form-input id="make" v-model="form.make" required/>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Модель"
                  label-align-sm="right"
                  label-for="model"
              >
                <b-form-input id="model" v-model="form.model" type="text" required/>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Год выпуска"
                  label-align-sm="right"
                  label-for="yearMake"
              >
                <b-form-input
                    id="yearMake"
                    v-model="form.yearMake"
                    type="number"
                    required
                    min="1900"
                    max="2021"
                    trim
                />
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Масса без нагрузки"
                  label-align-sm="right"
                  label-for="noload"
              >
                <b-form-input
                    id="noload"
                    v-model="form.noload"
                    type="number"
                    required
                    min="0"
                    trim
                />
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Максимальная масса"
                  label-align-sm="right"
                  label-for="maxWeight"
              >
                <b-form-input
                    id="maxWeight"
                    v-model="form.maxWeight"
                    type="number"
                    required
                    min="0"
                    trim
                />
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Пробег"
                  label-align-sm="right"
                  label-for="mileage"
              >
                <b-form-input
                    id="mileage"
                    v-model="form.mileage"
                    type="number"
                    required
                    min="0"
                    trim
                />
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Топливо"
                  label-align-sm="right"
                  label-for="fuel"
              >
                <b-form-select id="fuel" v-model="form.fuel" :options="fuelOptions" required/>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Тормозная система"
                  label-align-sm="right"
                  label-for="breaks"
              >
                <b-form-select id="breaks" v-model="form.breaks" :options="breaksOptions" required/>
              </b-form-group>
              <b-form-group
                  label-cols-sm="3"
                  label="Шины"
                  label-align-sm="right"
                  label-for="tyres"
              >
                <b-form-input id="tyres" v-model="form.tyres" required/>
              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="Особые отметки"
                  label-align-sm="right"
                  label-for="notes"
              >
                <b-form-select id="notes" v-model="form.notes" :options="notesOptions"/>
              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="Пункт"
                  label-align-sm="right"
                  label-for="unit"
              >
                <b-form-select id="unit" v-model="form.unit" :options="unitOptions" required/>
              </b-form-group>

              <b-form-group
                  label-cols-sm="3"
                  label="Комментарий"
                  label-align-sm="right"
                  label-for="comment"
              >
                <b-form-input id="comment" v-model="form.comment"/>
              </b-form-group>
            </b-card>
          </b-col>
        </b-form-row>

        <b-form-row>
          <hr>
          <b-col>
            <div class="d-flex justify-content-center my-1" id="submit_button">
              <b-overlay
                  :show="busy"
                  rounded="10"
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
              >
              <b-button type="submit" variant="primary" data-v-step="submit" :disabled="busy">
                Отправить запрос
              </b-button>
              </b-overlay>
              <!--        <b-button type="reset" variant="danger">-->
              <!--          Очистить форму-->
              <!--        </b-button>-->
            </div>
          </b-col>
        </b-form-row>

      </b-form>


      <b-modal ref="card_payment"
               class="h3"
               id="card_payment"
               v-bind:title="this.b_title"
               hide-footer
      >
        <div class="d-block text-center">
          <p class="my-4">Перейдите к оплате по кнопке ниже. После оплаты полис ДК будет отправлен на указанный вами
            адрес электронной почты {{ this.email }}</p>
          <p class="my-4">Вам также отправлено письмо со ссылкой на оплату</p>
        </div>


        <form method="POST" v-bind:action="computedAction">
          <div class="d-flex justify-content-center my-2">
            <b-button align="center" type="submit" value="Перевести">Перевод</b-button>
          </div>
        </form>
      </b-modal>


    </b-container>

  </div>


</template>

<script>


import axios from 'axios'

export default {
  data() {
    return {
      payment_url: null,
      selected_load_field: 1,
      load_field: [
        {text: 'Регистрационный знак', value: 1},
        {text: 'VIN', value: 2},
      ],
      numberState: false,
      serialState: false,
      busy: false,
      timeout: null,
      b_title: '',
      email: '',
      form: {
        id: null,
        phone: null,
        email: null,
        owType: 1,
        compName: '',
        owName: null,
        owSurname: null,
        owParent: null,
        docType: 1,
        serial: '',
        number: null,
        date: null,
        giver: null,
        category: 2,
        regSign: null,
        vin: '',
        bodyNum: '',
        chassis: '',
        make: null,
        model: null,
        yearMake: null,
        noload: '',
        maxWeight: '',
        mileage: '',
        fuel: null,
        breaks: null,
        tyres: null,
        notes: "0",
        comment: '',
        unit: 1,
        card_id: null,
        eaisto: null,
        added_date: null,
        status: null

      },
      owTypeOptions: [
        {text: 'Физ. лицо', value: 1},
        {text: 'Юр. лицо', value: 2}
      ],
      docTypeOptions: [{text: 'СРТС', value: 1}, {text: 'ПТС', value: 2}],
      categoryOptions: [
        {text: 'А | L - Мототранспортные средства', value: 1},
        {text: 'B | M1 - Легковые автомобили', value: 2},
        {text: 'B | N1 - Грузовые автомобили до 3.5 тонн', value: 3},
        {text: 'C | N2 - Грузовые автомобили 3.5 - 12 тонн', value: 4},
        {text: 'C | N3 - Грузовые автомобили свыше 12 тонн', value: 5},
        {text: 'E | O1 - Прицепы до 0.75 тонн', value: 6},
        {text: 'E | O2 - Прицепы 0.75 - 3.5 тонн', value: 7},
        {text: 'E | O3 - Прицепы 3.5 - 12 тонн', value: 8},
        {text: 'E | O4 - Прицепы свыше 12 тонн', value: 9}
      ],
      fuelOptions: [
        {text: 'Бензин', value: 1},
        {text: 'Дизель', value: 2},
        {text: 'Сжатый газ', value: 3},
        {text: 'Сжиженный газ', value: 4},
        {text: 'Бензин/Сжиженный газ', value: 5},
        {text: 'Бензин/Сжатый Газ', value: 6}
      ],
      breaksOptions: [
        {text: 'Гидравлическая', value: 1},
        {text: 'Пневматическая', value: 2},
        {text: 'Механическая', value: 3},
        {text: 'Комбинированная', value: 4}
      ],
      notesOptions: [
        {text: 'Нет отметок', value: "0"},
        {text: 'Исп. для учебной езды', value: "1"},
        {text: 'Исп. в качестве такси', value: "2"},
        {text: 'Исп. для маршрутных перевозок', value: "3"}
        // {
        //   text:
        //     'Установлено газобалонное оборудование (пропан - сжиженный газ)',
        //   value: 5
        // },
        // {
        //   text: 'Установлено газобалонное оборудование (метан - сжатый газ)',
        //   value: 6
        // }
      ],
      unitOptions: [
        {text: 'Передвижной пункт', value: 1},
        {text: 'Стационарный пункт', value: 2}
      ]
    }
  },
  beforeDestroy() {
    this.clearTimeout()
  },
  methods: {
    async loadCardData() {
      let me = this
      this.busy = true
      const csrftoken = this.getCookie('csrftoken');
      await axios.post(
          'https://e-dk.ru/api/load_card_data',
          {
            "type_id": this.selected_load_field,
            "value": (this.selected_load_field === 1) ? this.form.regSign : this.form.vin
          },
          {
            headers: {'X-CSRFToken': csrftoken},
            timeout: 10000
          })

          .then(response => {
            if (response.data.success) {
              let data = response.data.data

              me.form.make = data.mark
              me.form.model = data.model
              me.form.yearMake = data.yearIssue
              me.form.vin = data.vin
              me.form.bodyNum = data.bodyNumber
              me.form.chassis = data.chassisNumber
              me.form.regSign = data.regNumber
              me.form.docType = data.docType
              me.form.serial = data.docSeries
              me.form.number = data.docNumber
              try {
                me.form.date = data.docIssued.split('.').reverse().join('-')
              } catch (e) {
                console.log(e)
              }
              me.form.owType = data.owType
              if (parseInt(data.owType) !== 1) {
                me.form.owType = 2
                me.form.compName = data.compName

              } else {
                me.form.owType = 1
                me.form.owSurname = data.lastname
                me.form.owName = data.firstname
                me.form.owParent = data.patronymic
              }

              me.form.giver = data.giver
              me.form.category = data.category
              me.form.noload = data.noload
              me.form.maxWeight = data.maxWeight
              me.form.fuel = data.fuel
              me.form.breaks = data.breaks
              me.form.tyres = data.tyres

              me.form.notes = data.notes
              me.form.comment = data.comment
              this.makeToast('Данные успешно подгружены', "success")
            } else {
              this.makeToast(response.data.message, "danger")
            }

          }).catch(error => {
            this.busy = false
            this.onReset()
            console.log(error)
            this.makeToast('Данные по указанному регистрационному номеру не найдены', "warning")

          });
      this.setTimeout(() => {
        this.busy = false
      })

    },
    async getPaymentURL(response) {
      const csrftoken = this.getCookie('csrftoken');
      const request_id = response.data.id
      let data = this.form
      await axios
          .post(
              'https://e-dk.ru/api/payment_url', {
                request_id: request_id
              }, {
                headers: {'X-CSRFToken': csrftoken}
              }
          )
          .then(response => {
            if (response) {

              this.payment_url = response.data.payUrl
              this.b_title = `Перевод денежных средств по заявке`
              this.form.id = request_id
              this.email = data.email
              this.$refs['card_payment'].show()
              this.busy = false
              this.onReset()

            } else {
              this.makeToast(response, "danger")
            }
          })
          .catch(e => {
            this.errors.push(e)
          })
    },
    async onSubmit(evt) {
      evt.preventDefault();
      this.busy = true;
      const csrftoken = this.getCookie('csrftoken');
      let data = this.form
      data['client'] = "https://xn-----8kcsnkbmhldfbbhwoobfk4c5j.xn--p1ai"

      if (!this.stateWeight) {
        this.makeToast('Масса без нагрузки больше Максимальная масса', "danger")

      } else if (!this.stateIdent) {
        this.makeToast('Укажите VIN, Номер кузова или Номер рамы', "danger")

      } else {

        await axios
            .post(
                'https://e-dk.ru/api/cards/', data, {
                  headers: {'X-CSRFToken': csrftoken}
                }
            )
            .then(response => {
              if (response) {
                this.getPaymentURL(response)

              } else {
                this.makeToast(response, "danger")
              }
            })
            .catch(e => {
              this.errors.push(e)
            })
      }
    },
    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    setTimeout(callback) {
      this.clearTimeout()
      this.timeout = setTimeout(() => {
        this.clearTimeout()
        callback()
      }, 1000)
    },
    // onHidden() {
    //   // Return focus to the button once hidden
    //   this.$refs.button.focus()
    // },
    onReset() {
      // this.payment_url = null
      // Reset our form values
      this.form.phone = null
      this.form.email = null
      this.form.owType = 1
      this.form.compName = ''
      this.form.owName = null
      this.form.owSurname = null
      this.form.owParent = ''
      this.form.docType = 1
      this.form.serial = ''
      this.form.number = null
      this.form.date = null
      this.form.giver = null
      this.form.category = 2
      this.form.regSign = ''
      this.form.vin = ''
      this.form.bodyNum = ''
      this.form.chassis = ''
      this.form.make = null
      this.form.model = null
      this.form.yearMake = null
      this.form.noload = ''
      this.form.maxWeight = ''
      this.form.mileage = ''
      this.form.fuel = null
      this.form.breaks = null
      this.form.tyres = null
      this.form.notes = "0"
      this.form.comment = ''
      this.form.unit = 1

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    makeToast(message, variant = null) {
      this.$bvToast.toast(message, {
        title: `Сообщение`,
        variant: variant,
        toaster: 'b-toaster-top-right',
        solid: true,
      })
    },
    formatter(value) {
      return value.toUpperCase()
    }
  },

  async mounted() {
    // const {data} = await axios.get(`https://e-dk.ru/api/clients/`);
    // eslint-disable-next-line no-debugger
    // this.yandex_wallet = data.find(origin => origin.url === location.origin).yandex_wallet
    // this.payment_cost = data.find(origin => origin.url === location.origin).payment_cost
  },
  computed: {
    computedAction: function () {
      return this.payment_url
    },
    stateWeight() {
      return Number(this.form.noload) < Number(this.form.maxWeight)
    },
    stateIdent() {
      return (!(this.form.vin === '' && this.form.bodyNum === '' && this.form.chassis === ''))
    },
  }


}
</script>

